.music {
  width: 60px;
  height: 16px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.music .bar {
  width: 4px;
  border-radius: 10px;
  background: white;
  animation: loader 1.5s ease-in-out infinite;
}

@keyframes loader {
  0%,
  100% {
    height: 2px;
  }

  50% {
    height: 16px;
  }
}

.music .bar:nth-child(1) {
  background: #031e41;
  animation-delay: 1s;
}

.music .bar:nth-child(2) {
  background: #0c4da2;
  animation-delay: 0.8s;
}

.music .bar:nth-child(3) {
  background: #09418a;
  animation-delay: 0.6s;
}

.music .bar:nth-child(4) {
  background: #6ea8f5;
  animation-delay: 0.4s;
}

.music .bar:nth-child(5) {
  background: #b8d8fd;
  animation-delay: 0.2s;
}

.music .bar:nth-child(6) {
  background: #b8d8fd;
  animation-delay: 0.2s;
}

.music .bar:nth-child(7) {
  background: #6ea8f5;
  animation-delay: 0.4s;
}

.music .bar:nth-child(8) {
  background: #09418a;
  animation-delay: 0.6s;
}

.music .bar:nth-child(9) {
  background: #0c4da2;
  animation-delay: 0.8s;
}

.music .bar:nth-child(10) {
  background: #031e41;
  animation-delay: 1s;
}
